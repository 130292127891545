export interface PricePerformanceOption {
  id: string
  label: string
  year?: number
  quarter?: number
}

export const useExchangeListing = defineStore('exchange-listing', () => {
  const auth = useAuth()

  type RankedExchanges = SuccessResponseBody<'/exchange-listing/exchanges/ranked'>
  const rankedExchanges = ref<RankedExchanges | null>(null)

  interface SelectedExchange {
    id: string
    label: string
    image: string
  }
  const rankedExchangesSelect = ref<Array<SelectedExchange>>([])

  // we make our lives a little bit easier by baking in our default selected exchange
  const selectedExchange = ref<SelectedExchange>({
    id: 'binance',
    label: 'Binance',
    image: resolveLogoWithFallback('exchange', 'CEX-Binance.jpg'),
  })

  // get the ranked exchanges and transform the image for the dropdown
  // this is an async function so we can await it in a setup script
  async function fetchRankedExchanges() {
    const { data, error } = await useQuery('/exchange-listing/exchanges/ranked')

    if (error.value) {
      throw createError({
        statusMessage: `Failed to fetch exchanges`,
      })
    }

    if (data.value) {
      rankedExchanges.value = data.value
      rankedExchangesSelect.value = data.value.map(e => ({
        id: e.id,
        label: e.name,
        image: resolveLogoWithFallback('exchange', e.image),
      }))
    }
  }

  const { data: exchangeDetails } = useQuery('/exchange-listing/exchange-profile/{projectId}', {
    params: () => ({
      path: {
        projectId: auth.project!.id,
      },
      query: {
        exchangeId: selectedExchange.value.id,
      },
    }),
  })

  // this data is used to populate the time period selector in the price performance chart
  const { data: performanceQuarters } = useQuery('/exchange-listing/performance-quarters')
  const performanceQuartersOptions = computed(() => {
    return [
      {
        id: 'all',
        label: 'All Time',
        year: undefined,
        quarter: undefined,
      },
      ...(performanceQuarters.value || []).map(q => ({
        id: `${q.quarter}-${q.year}`,
        label: `Q${q.quarter}'${q.year.toString().slice(2)}`,
        year: q.year,
        quarter: q.quarter,
      })),
    ]
  })
  const selectedPeriod = ref(performanceQuartersOptions.value?.[0])
  const { data: popPerformance, loading: popPerformanceLoading } = useQuery('/exchange-listing/avg-post-tge-pop-performance', {
    params: () => ({
      query: {
        year: selectedPeriod.value.year,
        quarter: selectedPeriod.value.quarter,
      },
    }),
  })

  return {
    fetchRankedExchanges,
    rankedExchanges,
    rankedExchangesSelect,
    selectedExchange,
    exchangeDetails,
    performanceQuarters,
    performanceQuartersOptions,
    selectedPeriod,
    popPerformance,
    popPerformanceLoading,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useExchangeListing, import.meta.hot))
}
